export const getProducerBusinessQueryGql = `query GetProducerAndBusiness ($input: ByIdInput!) {
  producer(input: $input) {
    id
    acceptedTermsAt
    email
    phoneNumber
    firstName
    lastName
    roles
    avatar
    business {
      id
      status
      level
      subdomain
      customDomain
      metadata {
        title
      }
      verificationDetails {
        status
        collectNow
        collectSoon
        collectEventually
      }
    }
  }
}`;

export const getProducerQueryGql = `query GetProducer ($input: ByIdInput!) {
  producer(input: $input) {
    id
    acceptedTermsAt
    email
    phoneNumber
    firstName
    lastName
    roles
    avatar
  }
}`;

export const getProducerAccountQueryGql = `query GetProducerAccount ($input: ByIdInput!) {
  producer(input: $input) {
    id
    acceptedTermsAt
    email
    phoneNumber
    firstName
    lastName
    roles
    avatar
    business {
      id
      status
      level
    }
    card {
      id
      lastFour
      brand
      expMonth
      expYear
    }
  }
}`;

export const getProducerInvoiceHistoryQueryGql = `query GetProducerInvoiceHistory ($input: ByIdInput!, $filterInput: FilterInvoicesInput, $pagination: PaginationInput) {
  producer(input: $input) {
    invoices(input: $filterInput, pagination: $pagination) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          createdAt
          descriptor
          status
          interval {
            start
            end
          }
          amountPaid {
            amount
            currency {
              abbreviation
              symbol
            }
          }
          total {
            amount
            currency {
              abbreviation
              symbol
            }
          }
          card {
            id
            lastFour
            brand
            expMonth
            expYear
          }
        }
      }
    }
  }
}`;

export const getProducerCreditQueryGql = `query GetProducerCredit ($input: ByIdInput!) {
  producer(input: $input) {
    credit {
      amount
      currency {
        abbreviation
        symbol
      }
    }
  }
}`;

export const createProducerMutationGql = `mutation CreateProducer ($input: CreateProducerInput!) {
  createProducer(input: $input) {
    producer {
      id
      roles
      firstName
      lastName
      email
      phoneNumber
    }
  }
}`;

export const updateProducerMutationGql = `mutation UpdateProducer ($input: UpdateProducerInput!) {
  updateProducer(input: $input) {
    producer {
      id
      firstName
      lastName
      email
      phoneNumber
      avatar
    }
  }
}`;
