import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Elements } from '@stripe/react-stripe-js';
import { createContext, ReactNode, useState } from 'react';
import getStripe from 'utils/get-stripejs';

export interface ICottageAppContext {
  businessId: string;
  businessSubdomain: string;
  businessTitle: string;
  businessLevel: string;
  businessCustomDomain?: string | undefined;
  locationId: string;
  producerId: string;
  setBusinessId: React.Dispatch<React.SetStateAction<string>>;
  setBusinessSubdomain: React.Dispatch<React.SetStateAction<string>>;
  setBusinessCustomDomain: React.Dispatch<React.SetStateAction<string>>;
  setBusinessTitle: React.Dispatch<React.SetStateAction<string>>;
  setBusinessLevel: React.Dispatch<React.SetStateAction<string>>;
  setLocationId: React.Dispatch<React.SetStateAction<string>>;
  setProducerId: React.Dispatch<React.SetStateAction<string>>;
}

export const AppContext = createContext<ICottageAppContext>({
  businessId: '',
  businessSubdomain: '',
  businessCustomDomain: '',
  businessTitle: '',
  businessLevel: '',
  locationId: '',
  producerId: '',
  setBusinessId: () => null,
  setBusinessSubdomain: () => null,
  setBusinessCustomDomain: () => null,
  setBusinessTitle: () => null,
  setBusinessLevel: () => null,
  setLocationId: () => null,
  setProducerId: () => null,
});

const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const stripePromise = getStripe();
  const [businessId, setBusinessId] = useState('');
  const [businessSubdomain, setBusinessSubdomain] = useState('');
  const [businessCustomDomain, setBusinessCustomDomain] = useState('');
  const [businessTitle, setBusinessTitle] = useState('');
  const [businessLevel, setBusinessLevel] = useState('');
  const [locationId, setLocationId] = useState('');
  const [producerId, setProducerId] = useState('');

  return (
    <Elements stripe={stripePromise}>
      <AppContext.Provider
        value={{
          businessId,
          businessSubdomain,
          businessCustomDomain,
          businessTitle,
          businessLevel,
          locationId,
          producerId,
          setBusinessId,
          setBusinessSubdomain,
          setBusinessCustomDomain,
          setBusinessTitle,
          setBusinessLevel,
          setLocationId,
          setProducerId,
        }}>
        {children}
      </AppContext.Provider>
    </Elements>
  );
};

export default AppContextProvider;
