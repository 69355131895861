export enum PageRoutes {
  ACCOUNT = '/account',
  NOT_FOUND = '/404',
  HOME = '/',
  SIGN_IN = '/signin',
  SIGN_UP = '/signup',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  ONBOARDING = '/onboarding',
  LOCATION_DASHBOARD = '/business/:subdomain/location/:pathSegment',
  DASHBOARD = '/business/:subdomain',
}

export interface Params {
  subdomain: string;
  pathSegment?: string;
}
